export interface IState {
  assignmentList: IAssignment[]
  filters: IFilters
  selectedFilters: IFilterListItem[]
  totalHits: number
  currentHits: number
  currentPage: number
  pages: number
  assignmentsPerPage: number
  query: string
  showFilter: boolean
  showAllAmount: number
  isMobile: boolean
}

export interface IAssignment {
  id: number
  applicationEndDate: string
  title: string
  employmentType: string
  employmentGrade: string
  occupationArea: string
}

export interface IFilters {
  occupationArea: IFilter
  employmentGrade: IFilter
  employmentType: IFilter
}

export interface IFilter {
  title: string
  filterList: IFilterListItem[]
}

export interface IFilterListItem {
  name: string
  selected: boolean
}

const defaultState: IState = {
  assignmentList: [],
  filters: {
    occupationArea: {
      title: '',
      filterList: []
    },
    employmentGrade: {
      title: '',
      filterList: []
    },
    employmentType: {
      title: '',
      filterList: []
    }
  },
  selectedFilters: [],
  totalHits: 0,
  currentHits: 0,
  currentPage: 0,
  pages: 0,
  assignmentsPerPage: 10,
  showAllAmount: 1000,
  query: '',
  showFilter: false,
  isMobile: false
}

export default defaultState;
