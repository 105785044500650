
import Vue from 'vue'
import { IFilterListItem, IFilters } from '../store/state'
import { setFilter, setPage } from '../store/actions'

// Interfaces for component typechecking!
interface FiltersData {

}

interface FiltersMethods {
	setFilter(filter: IFilterListItem, index: number, page: number): void
}

interface FiltersComputed {
	showFilter: boolean
	filters: IFilters
}

interface FiltersProps {

}

export default Vue.extend<FiltersData, FiltersMethods, FiltersComputed, FiltersProps>({
	name: 'Filters',
	computed: {
		showFilter() {
			return this.$store.getters.showFilter
		},
		filters() {
			return this.$store.getters.filters
		},
	},
	methods: {
		setFilter(filter, index, page) {
			this.$store.dispatch(setFilter({
				filter: filter,
				index: index,
				unset: false
			}))
			this.$store.dispatch(setPage(page))
		},
	},
	components: {
	}
})
