import { Getter } from "vuex";
import { IState } from './state';

const assignmentList: Getter<IState, IState> = (state) => {
  const startAtAssignment = state.currentPage * state.assignmentsPerPage
  const endAtAssignment = (state.currentPage + 1) * state.assignmentsPerPage
  const query = new RegExp(state.query.toLowerCase(), 'g')
  
  const selectedOccupationAreaFilters = state.filters.occupationArea.filterList
    .reduce((returnedList, filter) => {
      filter.selected && returnedList.push(filter.name)
      return returnedList
    }, <string[]>[])

  const selectedEmploymentGradeFilters = state.filters.employmentGrade.filterList
  .reduce((returnedList, filter) => {
    filter.selected && returnedList.push(filter.name)
    return returnedList
  }, <string[]>[])

  const selectedEmploymentTypeFilters = state.filters.employmentType.filterList
  .reduce((returnedList, filter) => {
    filter.selected && returnedList.push(filter.name)
    return returnedList
  }, <string[]>[])

  let list = state.assignmentList
  .filter(assignment => {
    return assignment.title.toLowerCase().match(query) 
      || assignment.occupationArea.toLowerCase().match(query) 
      || assignment.employmentType.toLowerCase().match(query)
      || assignment.employmentGrade.toLowerCase().match(query)
  })

  if(selectedOccupationAreaFilters.length) {
    list = list.filter(assignment => {
        return selectedOccupationAreaFilters.includes(assignment.occupationArea) 
      })
  }
  if(selectedEmploymentGradeFilters.length) {
    list = list.filter(assignment => {
        return selectedEmploymentGradeFilters.includes(assignment.employmentGrade) 
      })
  }
  if(selectedEmploymentTypeFilters.length) {
    list = list.filter(assignment => {
        return selectedEmploymentTypeFilters.includes(assignment.employmentType) 
      })
  }
  
  state.currentHits = list.length
  return list.slice(startAtAssignment, endAtAssignment)
};

const filters: Getter<IState, IState> = (state) => state.filters;
const selectedFilters: Getter<IState, IState> = (state) => state.selectedFilters;
const hasSelectedFilters: Getter<IState, IState> = (state) => {
  return state.selectedFilters.some((filter) => filter.selected)
};
const query: Getter<IState, IState> = (state) => state.query;
const totalHits: Getter<IState, IState> = (state) => state.totalHits;
const currentHits: Getter<IState, IState> = (state) => state.currentHits;
const currentPage: Getter<IState, IState> = (state) => state.currentPage;
const pages: Getter<IState, IState> = (state) => {
  state.pages = Math.ceil(state.currentHits / state.assignmentsPerPage)
  return state.pages
};
const assignmentsPerPage: Getter<IState, IState> = (state) => state.assignmentsPerPage;
const showAllAmount: Getter<IState, IState> = (state) => state.showAllAmount;
const showFilter: Getter<IState, IState> = (state) => state.showFilter;
const isMobile: Getter<IState, IState> = (state) => state.isMobile;

export default {
  assignmentList,
  filters,
  selectedFilters,
  query,
  totalHits,
  currentHits,
  currentPage,
  pages,
  assignmentsPerPage,
  showAllAmount,
  showFilter,
  hasSelectedFilters,
  isMobile
}
