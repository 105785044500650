
import Vue from 'vue'
import { toggleFilter } from '../store/actions'

// Interfaces for component typechecking!
interface FilterButtonData {

}

interface FilterButtonMethods {
	toggleFilter(): void
}

interface FilterButtonComputed {
	showFilter: boolean
}

interface FilterButtonProps {

}

export default Vue.extend<FilterButtonData, FilterButtonMethods, FilterButtonComputed, FilterButtonProps>({
	name: 'FilterButton',
	computed: {
		showFilter() {
			return this.$store.getters.showFilter
		}
	},
	methods: {
		toggleFilter() {
			this.$store.dispatch(toggleFilter(undefined))
		}
	},
	components: {
	}
})
