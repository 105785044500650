import { MutationTree, Mutation } from 'vuex';
import { IState, IFilterListItem } from './state';
import { ISelectedFilter } from './actions';
import { ActionPayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}
const paginateMutation: MutationHandler<boolean> = (state, { payload }) => {
	const increase = payload
	if (increase) {
		if(state.currentPage >= state.pages - 1) return
		state.currentPage += 1;
	} else {
		if (state.currentPage <= 0) return
		state.currentPage -= 1;
	}
}
const setPageMutation: MutationHandler<number> = (state, { payload }) => {
	Object.assign(state, {currentPage: payload});
}
const setFilterMutation: MutationHandler<ISelectedFilter> = (state, { payload }) => {
	if(payload.unset) {
		state.selectedFilters.splice(payload.index, 1)
		Object.assign(payload.filter, {selected: false})
	} else {
		if(payload.filter.selected) {
			state.selectedFilters.push(payload.filter)
		} else {
			state.selectedFilters.splice(payload.index, 1)
		} 
	}
}
const setQueryMutation: MutationHandler<string> = (state, { payload }) => {
	Object.assign(state, {query: payload});
	Object.assign(state, {currentPage: 0});
}
const toggleFilterMutation: MutationHandler<string> = (state, { payload }) => {
	Object.assign(state, {showFilter: !state.showFilter});
}
const showAllMutation: MutationHandler<undefined> = (state, { payload }) => {
	Object.assign(state, {assignmentsPerPage: state.showAllAmount});
	Object.assign(state, {currentPage: 0});
}
const resetFiltersMutation: MutationHandler<undefined> = (state, { payload }) => {
	state.selectedFilters.forEach(filter => filter.selected = false)
	state.selectedFilters.splice(0, state.selectedFilters.length)
}
const checkMobileMutation: MutationHandler<number> = (state, { payload }) => {
	Object.assign(state, {
		isMobile: payload < 1024
	})
}
const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	paginate: paginateMutation,
	setPage: setPageMutation,
	setQuery: setQueryMutation,
	toggleFilter: toggleFilterMutation,
	resetFilters: resetFiltersMutation,
	showAll: showAllMutation,
	checkMobile: checkMobileMutation,
	setFilter: setFilterMutation
}

export default mutations
