
import Vue from 'vue'
import { showAll } from '../store/actions'

// Interfaces for component typechecking!
interface CountData {

}

interface CountMethods {
	showAll(): void
}

interface CountComputed {
	fromValue: number
	toValue: number
	countString: string
	assignmentsPerPage: number
	showAllAmount: number
	currentPage: number
	currentHits: number
	pages: number
}

interface CountProps {

}

export default Vue.extend<CountData, CountMethods, CountComputed, CountProps>({
	name: 'Count',
	computed: {
		fromValue() {
			return this.currentPage * this.assignmentsPerPage + 1
		},
		toValue() {
  		return this.currentPage >= (this.pages - 1) ? 
				this.currentHits : 
				(this.currentPage + 1) * this.assignmentsPerPage
		},
		countString() {
			return this.assignmentsPerPage < this.showAllAmount ?
				`Visar ${this.fromValue} - ${this.toValue} av ${this.currentHits}` :
				'Visar alla'
		},
		assignmentsPerPage() {
			return this.$store.getters.assignmentsPerPage
		},
		showAllAmount() {
			return this.$store.getters.showAllAmount
		},
		currentHits() {
			return this.$store.getters.currentHits
		},
		currentPage() {
			return this.$store.getters.currentPage
		},
		pages() {
			return this.$store.getters.pages
		}
	},
	methods: {
		showAll() {
			this.$store.dispatch(showAll(undefined))
		}
	},
	components: {
	}
})
