import { IState, IFilterListItem } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';

type PayloadType = 'initApp' | 'setState' | 'paginate' | 'setPage' | 'setQuery' | 'toggleFilter' | 'showAll' | 'resetFilters' | 'checkMobile' | 'setFilter'
export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}
export interface IPaginate {
	increase: boolean
}

export interface ISelectedFilter {
	filter: IFilterListItem,
	index: number,
	unset: boolean
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;


// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})
export const paginate: ActionCreator<IPaginate> = (payload) => ({
	type: 'paginate',
	payload: payload
})
export const setPage: ActionCreator<number> = (pageNumber) => ({
	type: 'setPage',
	payload: pageNumber
})
export const setFilter: ActionCreator<ISelectedFilter> = (filter) => ({
	type: 'setFilter',
	payload: filter
})
export const setQuery: ActionCreator<string> = (query) => ({
	type: 'setQuery',
	payload: query
})
export const toggleFilter: ActionCreator<undefined> = (payload) => ({
	type: 'toggleFilter',
	payload: payload
})
export const showAll: ActionCreator<undefined> = (payload) => ({
	type: 'showAll',
	payload: payload
})
export const resetFilters: ActionCreator<undefined> = (payload) => ({
	type: 'resetFilters',
	payload: payload
})
export const checkMobile: ActionCreator<number> = payload => ({
	type: 'checkMobile',
	payload: payload
})
// Action handlers
const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})
}
const paginateAction: ActionHandler<IPaginate> = ({ commit }, { payload }) => {
	commit({
		type: 'paginate',
		payload: payload.increase
	})
}
const setPageAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setPage',
		payload
	})
}
const setFilterAction: ActionHandler<ISelectedFilter> = ({ commit }, { payload }) => {
	commit({
		type: 'setFilter',
		payload
	})
}
const setQueryAction: ActionHandler<string> = ({ commit }, { payload }) => {
	commit({
		type: 'setQuery',
		payload
	})
}
const toggleFilterAction: ActionHandler<string> = ({ commit }, { payload }) => {
	commit({
		type: 'toggleFilter',
		payload
	})
}
const showAllAction: ActionHandler<undefined> = ({ commit }, { payload }) => {
	commit({
		type: 'showAll',
		payload
	})
}
const resetFiltersAction: ActionHandler<undefined> = ({ commit }, { payload }) => {
	commit({
		type: 'resetFilters',
		payload
	})
}
const checkMobileAction: ActionHandler<number> = ({ commit, state }, { payload }) => {
	commit({
		type: 'checkMobile',
		payload
	})
}
const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	paginate: paginateAction,
	setPage: setPageAction,
	setFilter: setFilterAction,
	setQuery: setQueryAction,
	toggleFilter: toggleFilterAction,
	showAll: showAllAction,
	resetFilters: resetFiltersAction,
	checkMobile: checkMobileAction
}

export default actions;
