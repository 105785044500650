
import Vue from 'vue'
import { IFilterListItem } from '../store/state'
import { resetFilters, setFilter, setPage } from '../store/actions'

// Interfaces for component typechecking!
interface SelectedFiltersData {

}

interface SelectedFiltersMethods {
	setFilter(filter: IFilterListItem, index: number, page: number): void
	resetFilters(): void
}

interface SelectedFiltersComputed {
	selectedFilters: IFilterListItem[]
	hasSelectedFilters: boolean
	currentHits: number
}

interface SelectedFiltersProps {

}

export default Vue.extend<SelectedFiltersData, SelectedFiltersMethods, SelectedFiltersComputed, SelectedFiltersProps>({
	name: 'SelectedFilters',
	computed: {
		selectedFilters() {
			return this.$store.getters.selectedFilters
		},
		hasSelectedFilters() {
			return this.$store.getters.hasSelectedFilters
		},
		currentHits() {
			return this.$store.getters.currentHits
		},
	},
	methods: {
		resetFilters() {
			this.$store.dispatch(resetFilters(undefined))
		},
		setFilter(filter, index, page) {
			this.$store.dispatch(setFilter({
				filter: filter,
				index: index,
				unset: true
			}))
			this.$store.dispatch(setPage(page))
		},
	},
	components: {
	}
})
