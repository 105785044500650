
import Vue from 'vue'
import { IAssignment } from '../../store/state'

// Interfaces for component typechecking!
interface AssignmentItemData {

}

interface AssignmentItemMethods {

}

interface AssignmentItemComputed {
	even: boolean
}

interface AssignmentItemProps {
	item: IAssignment
	index: number
}

export default Vue.extend<AssignmentItemData, AssignmentItemMethods, AssignmentItemComputed, AssignmentItemProps>({
	name: 'AssignmentItem',
	props: {
		item: {},
		index: () => 0
	},
	computed: {
		even() {
			return this.index % 2 === 0
		}
	},
	components: {
	}
})
