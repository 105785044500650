
import Vue from "vue";
import { IAssignment } from "../store/state";
import AssignmentItem from "./AssignmentItem.vue";

// Interfaces for component typechecking!
interface AssignmentListData {}

interface AssignmentListMethods {}

interface AssignmentListComputed {
  assignmentList: IAssignment[];
}

interface AssignmentListProps {}

export default Vue.extend<
  AssignmentListData,
  AssignmentListMethods,
  AssignmentListComputed,
  AssignmentListProps
>({
  name: "AssignmentList",
  computed: {
    assignmentList() {
      return this.$store.getters.assignmentList;
    },
  },
  methods: {},
  components: {
    AssignmentItem,
  },
});
