
import Vue from 'vue'
import { paginate, setPage } from '../store/actions'

// Interfaces for component typechecking!
interface PaginationData {

}

interface PaginationMethods {
	incrementPage(): void
	decrementPage(): void
	setPage(pageNumber: number): void
}

interface PaginationComputed {
	pages: number
	currentPage: number
	showAllAmount: number
	assignmentsPerPage: number
}

interface PaginationProps {
	isMobile: boolean
}

export default Vue.extend<PaginationData, PaginationMethods, PaginationComputed, PaginationProps>({
	name: 'Pagination',
	props: {
		isMobile: () => false
	},
	computed: {
		pages() {
			return this.$store.getters.pages
		},
		currentPage() {
			return this.$store.getters.currentPage
		},
		showAllAmount() {
			return this.$store.getters.showAllAmount
		},
		assignmentsPerPage() {
			return this.$store.getters.assignmentsPerPage
		}
	},
	methods: {
		incrementPage() {
			this.$store.dispatch(paginate({
				increase: true
			}))
		},
		decrementPage() {
			this.$store.dispatch(paginate({
				increase: false
			}))
		},
		setPage(pageNumber) {
			this.$store.dispatch(setPage(pageNumber))
		},
	},
	components: {
	}
})
