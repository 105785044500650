
import Vue from 'vue'
import { setQuery } from '../store/actions'
// Interfaces for component typechecking!
interface SearchFormData {
	inputQuery: string
}

interface SearchFormMethods {
	search(event: Event): void
}

interface SearchFormComputed {
	totalHits: number
}

interface SearchFormProps {

}

export default Vue.extend<SearchFormData, SearchFormMethods, SearchFormComputed, SearchFormProps>({
	name: 'SearchForm',
	data() {
		return {
			inputQuery: ''
		}
	},
	computed: {
		totalHits() {
			return this.$store.getters.totalHits
		}
	},
	methods: {
		search(event) {
			event.preventDefault();
			const query = this.inputQuery;
			this.$store.dispatch(setQuery(query))
		}
	},
	components: {
	}
})
