
import Vue from 'vue'
import Count from './components/Count.vue'
import SearchForm from './components/SearchForm.vue'
import FilterButton from './components/FilterButton.vue'
import Filters from './components/Filters.vue'
import SelectedFilters from './components/SelectedFilters.vue'
import AssignmentList from './components/AssignmentList.vue'
import AssignmentListMobile from './components/mobile/AssignmentListMobile.vue'
import Pagination from './components/Pagination.vue'
import { checkMobile } from './store/actions'
// Interfaces for component typechecking!
interface AppData {

}

interface AppMethods {

}

interface AppComputed {
	isMobile: boolean
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	computed: {
		isMobile() {
			return this.$store.getters.isMobile
		}
	},
	created() {
		this.$store.dispatch(checkMobile(window.innerWidth))

		addEventListener('resize', () => {
			const width = Math.max(
				document.body.scrollWidth,
				document.documentElement.scrollWidth,
				document.body.offsetWidth,
				document.documentElement.offsetWidth,
				document.documentElement.clientWidth,
				window.innerWidth
				);
			this.$store.dispatch(checkMobile(width))
		})
	},
	components: {
		Count,
		SearchForm,
		FilterButton,
		Filters,
		SelectedFilters,
		AssignmentList,
		AssignmentListMobile,
		Pagination
	}
})
